import React from 'react'

import Title from 'components/base/Title'
import Paragraph, { Theme as ParagraphTheme } from 'components/base/Paragraph'

import Modal from 'components/blocks/Modal/index'

import { Props } from './types'

const ModalForgetMe: React.FC<Props> = ({
  modalForgetMe,
  modalForgetMeSuccess,
  onCancel,
  onConfirm,
}): React.ReactElement => (
  <>
    <Modal
      isOpen={modalForgetMe.isOpen}
      label='Clear Information?'
      onRequestClose={modalForgetMe.onRequestClose}
      buttons={[
        {
          center: true,
          label: 'Yes, Clear',
          onClick: onConfirm,
          wide: true,
        },
        {
          center: true,
          label: 'Cancel',
          onClick: onCancel,
          secondary: true,
          wide: true,
        },
      ]}
    >
      <Title level={3} noMargin={true}>
        Clear Information?
      </Title>
      <Paragraph theme={ParagraphTheme.intro}>Are you sure you want to reset your quote information and start over?</Paragraph>
    </Modal>
    <Modal
      isOpen={modalForgetMeSuccess.isOpen}
      label='Information Cleared'
      onRequestClose={modalForgetMeSuccess.onRequestClose}
      buttons={[
        {
          center: true,
          label: 'Okay',
          onClick: modalForgetMeSuccess.onRequestClose,
          wide: true,
        },
      ]}
    >
      <Title level={3} noMargin={true}>
        Information Cleared
      </Title>
      <Paragraph theme={ParagraphTheme.intro}>All of your quote information has been cleared.</Paragraph>
    </Modal>
  </>
)

export default ModalForgetMe
