
import styled from 'styled-components'

import FONTS from 'constants/fonts'
import MEDIA from 'constants/media'

import InfoIcon from 'assets/images/icons/Info.svg'

export const InfoContent = styled.div`
  position: relative;
  background: transparent;
  cursor: default;
  z-index: 1;
`

export const TooltipWrap = styled.div`
  position: relative;
  float: right;
  height: 20px;
  width: 20px;
  margin-top: 5px;
  ${MEDIA.medium`
    height: 24px;
    width: 24px;
    margin-top: 3px;
  `}
`

export const InfoIconButton = styled.div`
  background-image: url(${InfoIcon});
  background-size: cover;
  height: 20px;
  width: 20px;
  display: block;
  cursor: pointer;
  &:hover {
    ${InfoContent} {
      transition: opacity 0.2s linear;
    }
  }
  ${MEDIA.medium`
    height: 24px;
    width: 24px;
  `}
`

export const InfoClose = styled.span`
  font-size: 18px;
  position: absolute;
  right: 8px;
  top: 2px;
  cursor: pointer;
`

export const InfoText = styled.div`
  background: #ffffff;
  border: 1px solid #a7a7a7;
  box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
  font-size: 18px;
  font-family: ${FONTS.FAMILYPRIMARY};
  font-weight: ${FONTS.WEIGHTREGULAR};
  line-height: 1.3;
  width: 280px;
  padding: 15px 30px 15px 15px;
  border-radius: 6px;
  margin: 0;
  z-index: 1;
`

export const InfoArrow = styled.div`
  position: absolute;
  right: 14px;
  z-index: 2;
  border-left: 20px solid transparent;
  border-top: 22px solid rgba(0, 0, 0, 0.2);

  &:before {
    border-left: 26px solid transparent;
    border-top: 22px solid #a7a7a7;
    border-bottom: none;
    content: '';
    position: absolute;
    right: 3px;
    top: unset;
    bottom: 3px;
  }
  &:after {
    border-left: 30px solid transparent;
    border-top: 24px solid #ffffff;
    border-bottom: none;
    content: '';
    position: absolute;
    right: 3px;
    top: unset;
    bottom: 4px;
  }
`

export const InfoArrowWrapper = styled.div`
  width: 30px;
  height: 24px;

  &[data-popper-placement='top-start'] {
    transform: rotate(0deg) scaleX(-1) translate3d(0px, 0px, 0px) !important;
  }

  &[data-popper-placement='bottom-end'] {
    transform: rotate(180deg) scaleX(-1) translate3d(250px, 0px, 0px) !important;
    top: -24px;
    bottom: unset;
  }

  &[data-popper-placement='bottom-start'] {
    transform: rotate(180deg) scaleX(1) translate3d(0px, 0px, 0px) !important;
    top: -24px;
    bottom: unset;
  }
`
