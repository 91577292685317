import styled from 'styled-components'

import MEDIA from 'constants/media'


import LogoImg from 'assets/images/inl-logo.svg'

const Logo = styled.span`
  background-color: transparent;
  background-image: url(${LogoImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 80px;
  max-width: 200px;
  width: 100%;
  display: inline-block;
  transition: all 0.7s ease;
  ${MEDIA.medium`
    height: 100px;
    padding: 30px;
    max-width: 400px;
    width: 100%;
  `}
`

export default Logo
