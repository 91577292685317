import React, { useContext } from 'react'
import {
  shallowEqual,
  useSelector,
} from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  getQuoteRequestValue,
  isQuoteRequestPristine,
} from 'state/quotes/selectors'

import { RootState } from 'state/types'

import { ForgetMeContext } from 'components/blocks/Modal/ForgetMe/context'

import { MainNavContext } from './context'

import MainNav from './index.presentational'

import {
  ContainerProps,
  ContextProps,
} from './types'

const MODAL_OPEN_CLASS = 'body--modal-open'

const MainNavContainer: React.FC<ContainerProps> = (props): React.ReactElement => {
  const [
    applicantFirstName,
    isPristine,
  ] = useSelector((state: RootState) => [
    getQuoteRequestValue(state, 'applicant.personalInfo.name.firstName'),
    isQuoteRequestPristine(state),
  ], shallowEqual)

  const {
    isOpen,
    setIsOpen,
  }: ContextProps = useContext(MainNavContext)

  const { setIsOpen: setIsForgetMeOpen } = useContext(ForgetMeContext)

  const show = (): void => {
    document.body.classList.add(MODAL_OPEN_CLASS)

    setIsOpen(true)
  }

  const hide = (): void => {
    document.body.classList.remove(MODAL_OPEN_CLASS)

    setIsOpen(false)
  }

  return React.createElement(MainNav, {
    applicantFirstName,
    hide,
    isOpen,
    onForgetMeConfirm: hide,
    onForgetMeOpen: (): void => setIsForgetMeOpen(true),
    show,
    showForgetMePrompt: !isPristine,
    ...props,
  })
}

export default withRouter(MainNavContainer)
