

import { Link as BaseLink } from 'react-router-dom'

import styled from 'styled-components'

import COLORS from 'constants/colors'
import FONTS from 'constants/fonts'
import LAYOUT from 'constants/layout'
import MEDIA from 'constants/media'


export const MenuWrap = styled.div`
  position: relative;
  width: 100%;
`

export const Menu = styled.div`
  background: ${COLORS.TAN};
  position: fixed;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: scroll;
  top: 0;
  left: 0;
  z-index: 2;
`

export const MenuInner = styled.div`
  max-width: ${LAYOUT.WIDTH};
  padding: 20px;
  margin: auto;
  text-align: center;
`

export const MainMenu = styled.div`
  position: relative;
  a {
    color: ${COLORS.BLACK};
    display: block;
    font-size: ${FONTS.FONTMENUMOBILE};
    font-weight: 300;
    line-height: 1.4em;
    margin-bottom: 5px;
    text-decoration: none;
    &:link,
    &:visited {
      color: ${COLORS.BLACK};
      text-decoration: none;
    }
    ${MEDIA.medium`
      margin-bottom: 10px;
      font-size: ${FONTS.FONTMENU};
    `}
  }
`

export const MainMenuContent = styled.div`
  clear: both;
  font-family: ${FONTS.FAMILYSECONDARY};
  padding: 0 20px;
  sup {
    font-size: 35%;
    top: -01.25em;
  }
  ${MEDIA.medium`
    padding: 40px 40px 0;
  `}
`

export const MainMenuContentTop = styled.div`
  clear: both;
  a {
    color: ${COLORS.BLACK};
    display: block;
    font-size: ${FONTS.FONTXLARGE};
    line-height: 1.2em;
    text-decoration: none;
    margin-bottom: 20px;
    &:link,
    &:visited {
      color: ${COLORS.BLACK};
      text-decoration: none;
    }
  }
`

export const ForgetMyInfoWrapper = styled.div`
  margin-bottom: 10px;
  button {
    background: transparent;
    border: none;
    font-size: ${FONTS.FONTXLARGE};
    line-height: 1.2em;
    text-decoration: none;
    margin: 0 7px;
  }
  ${MEDIA.medium`
    display: inline-block;
    margin-bottom: 20px;
  `}
`

export const ForgetMyInfoContent = styled.div`
  display: inline-block;
  position: relative;
  padding-right: 20px;
  ${MEDIA.medium`
    margin-right: 10px;
  `}
`

export const ToolTipWrapper = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
  top: 3px;
`

export const TopTitle = styled.div`
  font-size: ${FONTS.TITLETHREE};
  font-weight: ${FONTS.WEIGHTDEMI};
  margin-bottom: 10px;
`

export const ClientLogin = styled.div`
  a {
    font-weight:normal;
  }
  ${MEDIA.medium`
    display: inline-block;
  `}
`

export const Burger = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  height: 25px;
  position: absolute;
  top: 25px;
  padding-left: 1.25rem;
  width: 25px;
  &:focus {
    outline: none;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.25rem;
    width: 1.75rem;
    height: 5px;
    background: ${COLORS.BLUE};
    box-shadow: 0 17px 0 0 #333399, 0 8.5px 0 0 ${COLORS.BLUE};
  }
`

export const CloseX = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 45px;
  z-index: 3;
  &:after {
    content: '';
    height: 30px;
    border-left: 4px solid ${COLORS.BLUE};
    position: absolute;
    transform: rotate(45deg);
    left: 14px;
    top: 0;
  }
  &:before {
    content: '';
    height: 30px;
    border-left: 4px solid ${COLORS.BLUE};
    position: absolute;
    transform: rotate(-45deg);
    left: 14px;
    top: 0;
  }
`

export const Link = styled(BaseLink)`
  margin-left: auto;
  margin-right: auto;

  max-width: 250px;
  ${MEDIA.medium`
    max-width: 500px;
  `}
`
