import React from 'react'

import {
  TooltipWrap,
  InfoArrowWrapper,
  InfoArrow,
  InfoClose,
  InfoContent,
  InfoText,
  InfoIconButton,
} from './styles'

import { Props } from './types'

const Tooltip: React.FC<Props> = ({
  children,
  hide,
  isVisible,
  popperAttributes,
  popperStyles,
  setArrowElement,
  setButtonElement,
  setPopperElement,
  wrapRef,
  show,
}): React.ReactElement => (
  <TooltipWrap ref={wrapRef}>
    <InfoIconButton ref={setButtonElement} onClick={show} />
    {isVisible && (
      <InfoContent ref={setPopperElement} style={popperStyles.popper} {...popperAttributes.popper}>
        <InfoText>
          {children}
          <InfoClose onClick={hide}>
            X
          </InfoClose>
        </InfoText>
        <InfoArrowWrapper ref={setArrowElement} style={popperStyles.arrow} {...popperAttributes.popper}>
          <InfoArrow />
        </InfoArrowWrapper>
      </InfoContent>
    )}
  </TooltipWrap>
)

export default Tooltip
