import styled from 'styled-components'

import LAYOUT from 'constants/layout'


export const HeaderWrap = styled.div`
  background-color: transparent;
  width: 100%;
  position: fixed;
  padding: ${LAYOUT.PADDING} 0;
  top: 0;
  z-index: 4;
  transition: all 0.7s ease;
  &.header--hidden {
    top: -220px;
  }
  &.header--scrolling {
    background-color: rgba(255, 255, 255, 0.9);
    transition: all 0.7s ease;
    padding: 5px 0;
    .siteLogo {
      max-width: 200px;
      height: 80px;
      transition: all 0.7s ease;
    }
  }
`

export const HeaderContent = styled.div`
  max-width: ${LAYOUT.WIDTH};
  padding: 0 ${LAYOUT.PADDING};
  margin: auto;
  position: relative;
  width: 100%;
  z-index: 4;
  text-align: center;
  .siteLogo {
    margin: auto;
  }
`
