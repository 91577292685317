import React, {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react'
import { usePopper } from 'react-popper'

import { HeaderContext } from 'components/Header/context'

import Tooltip from './index.presentational'

const TooltipContainer: React.FC = (props): React.ReactElement => {
  const wrapRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null) // eslint-disable-line no-null/no-null
  const [
    buttonElement,
    setButtonElement,
  ] = useState<HTMLElement|undefined>(undefined)
  const [
    popperElement,
    setPopperElement,
  ] = useState<HTMLElement|undefined>(undefined)
  const [
    arrowElement,
    setArrowElement,
  ] = useState<HTMLElement|undefined>(undefined)

  const {
    isVisible: isHeaderVisible,
    isAtTop: isHeaderAtTop,
  } = useContext(HeaderContext)

  useEffect(() => {
    document.addEventListener('mousedown', clickListener)
    document.addEventListener('touchstart', clickListener)

    return (): void => {
      document.removeEventListener('mousedown', clickListener)
      document.removeEventListener('touchstart', clickListener)
    }
  }, [])

  const [
    isVisible,
    setIsVisible,
  ] = useState(false)

  const clickListener = (event: Event): void => {
    const { current } = wrapRef

    if (!current) {
      return
    }

    if (!current.contains(event.target as Node)) {
      setIsVisible(false)
    }
  }

  const hide = (e: React.MouseEvent): void => {
    e.stopPropagation()
    setIsVisible(false)
  }

  const show = (): void => {
    setIsVisible(true)
  }

  const padding = { top: (isHeaderVisible && !isHeaderAtTop && 94) || (isHeaderVisible && isHeaderAtTop && 144) || 0 }

  const {
    attributes,
    styles,
  } = usePopper(buttonElement, popperElement, {
    placement: 'top-end',
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowElement },
        data: { x: 15 },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: [
            'top-end',
            'top-start',
            'bottom-end',
            'bottom-start',
          ],
          padding,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [
            0,
            30,
          ],
        },
      },
    ],
  })

  return React.createElement(Tooltip, {
    hide,
    isVisible,
    popperAttributes: attributes,
    popperStyles: styles,
    setArrowElement: setArrowElement as unknown as React.RefObject<HTMLDivElement>,
    setButtonElement: setButtonElement as unknown as React.RefObject<HTMLDivElement>,
    setPopperElement: setPopperElement as unknown as React.RefObject<HTMLDivElement>,
    wrapRef,
    show,
    ...props,
  })
}

export default TooltipContainer
