import React, {
  useContext,
  useEffect,
  useState,
} from 'react'

import { HeaderContext } from './context'

import Header from './index.presentational'

const HeaderContainer: React.FC = (): React.ReactElement => {
  const [
    prevScrollpos,
    setPrevScrollpos,
  ] = useState(0)

  const {
    isShowOnScrollEnabled,
    isVisible,
    setIsVisible,
    isAtTop,
    setIsAtTop,
  } = useContext(HeaderContext)

  const handleScroll = (): void => {
    const newTop = window.scrollY < 40
    if (newTop !== isAtTop) {
      setIsAtTop(newTop)
    }

    const currentScrollPos: number = window.pageYOffset

    setPrevScrollpos(currentScrollPos)

    setIsVisible((prevScrollpos > currentScrollPos) && isShowOnScrollEnabled)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return (): void => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return React.createElement(Header, {
    isVisible,
    isAtTop,
  })
}

export default HeaderContainer
